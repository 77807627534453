import React from 'react'
import { styled } from '@mui/material/styles'

const StyledImageBlockContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'style',
})(({ style, theme }) => ({
  position: style && style.position,
  maxWidth: style && style.maxWidth,
  zIndex: style && style.zIndex,
  marginTop: style && style.marginTop,
  marginLeft: style && style.marginLeft,
  marginRight: style && style.marginRight,
}))

interface ImageBlockContainerProps {
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
  maxWidth?: number | string
  marginTop?: number | string
  marginLeft?: number | string
  marginRight?: number | string
  zIndex?: number
}

const ImageBlockContainer: React.FC<ImageBlockContainerProps> = ({
  position = 'relative',
  maxWidth = 480,
  marginTop = 160,
  marginLeft = 0,
  marginRight = 0,
  zIndex = 2,
  children,
}) => {
  return (
    <StyledImageBlockContainer style={{ position, maxWidth, marginTop, marginLeft, marginRight, zIndex }}>
      {children}
    </StyledImageBlockContainer>
  )
}

export default ImageBlockContainer
