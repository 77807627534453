import React from 'react'
import { styled } from '@mui/material/styles'

const StyledSectionContainer = styled('section', {
  shouldForwardProp: (prop) => prop !== 'style',
})(({ style, theme }) => ({
  fontFamily: 'Quicksand',
  width: '100%',
  overflow: 'hidden',
  background: style && style.background,
  backgroundColor: style && style.backgroundColor,
  marginTop: style && style.marginTop,
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}))

interface SectionContainerProps {
  backgroundColor?: string
  background?: string
  marginTop?: number
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  background = '',
  backgroundColor = '',
  marginTop = 0,
  children,
}) => {
  return <StyledSectionContainer style={{ background, backgroundColor, marginTop }}>{children}</StyledSectionContainer>
}

export default SectionContainer
