import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import vector from '../../img/List-vector.svg'
import pipeline from '../../img/Pipeline-image-dashboard.svg'
import mobileimage from '../../img/Pipeline-image-mobile.svg'
import ContentContainer from '../Container/ContentContainer'
import ImageBlockContainer from '../Container/ImageBlockContainer'
import SectionContainer from '../Container/SectionContainer'
import TextBlockContainer from '../Container/TextBlockContainer'

const Title = styled('div')(({ theme }) => ({
  color: '#2C2D68',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: 38,
  lineHeight: '41.8px',
  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '36px',
    textAlign: 'center',
  },
}))

const ListContainer = styled('div')(({ theme }) => ({
  color: '#555555',
  marginTop: 24,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 35,
    marginRight: 35,
  },
}))

const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
}))

const ListVector = styled('img')(({ theme }) => ({
  position: 'absolute',
  paddingTop: 10,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 5,
  },
}))

const ListText = styled('div')(({ theme }) => ({
  marginLeft: 60,
  fontFamily: 'Libre Franklin',
  fontWeight: 300,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '32px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 40,
    fontSize: 16,
    lineHeight: '25.6px',
  },
}))

const PipelineImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const MobileResponsiveImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const PipelineLayout: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const flexDirection = isMobile ? 'column' : 'row'
  const textMarginTop = isMobile ? 64 : 258
  const textMarginLeft = isMobile ? 0 : 186
  const textMarginRight = isMobile ? 0 : 50
  const imageMarginTop = isMobile ? 0 : 60

  return (
    <SectionContainer background="#F1F1F1">
      <ContentContainer justifyContent="normal" flexDirection={flexDirection} margin="0px auto" minHeight={737}>
        <TextBlockContainer
          textAlign="left"
          maxWidth={410}
          marginTop={textMarginTop}
          marginLeft={textMarginLeft}
          marginRight={textMarginRight}
        >
          <Title>Pipeline</Title>
          <ListContainer>
            <ListItem>
              <ListVector style={{ paddingBottom: isMobile ? 75 : 60 }} src={vector} alt="list vector" />
              <ListText>
                View your upcoming volume of incoming and outgoing mortgage transactions (including express refinances)
              </ListText>
            </ListItem>
            <ListItem>
              <ListVector style={{ paddingBottom: isMobile ? 50 : 30 }} src={vector} alt="list vector" />
              <ListText>See where your refinancing customers are moving to</ListText>
            </ListItem>
          </ListContainer>
        </TextBlockContainer>
        <ImageBlockContainer marginTop={imageMarginTop}>
          <MobileResponsiveImage src={mobileimage} alt="Pipeline dashboards" />
          <PipelineImage style={{ minWidth: 730 }} src={pipeline} alt="Pipeline dashboards" />
        </ImageBlockContainer>
      </ContentContainer>
    </SectionContainer>
  )
}

export default PipelineLayout
