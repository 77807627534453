import React from 'react'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'

import { ReactComponent as FacebookIcon } from '../../img/Icon-Facebook.svg'
import { ReactComponent as LinkedInIcon } from '../../img/Icon-LinkedIn.svg'
import { ReactComponent as TwitterIcon } from '../../img/Icon-Twitter.svg'
import { ReactComponent as YouTubeIcon } from '../../img/Icon-YouTube.svg'
import { ReactComponent as FooterLogo } from '../../img/PEXA-logo-white.svg'

const FooterContainer = styled('footer')(({ theme }) => ({
  minHeight: 120,
  background: '#4C4D68',
  [theme.breakpoints.down('sm')]: {
    minHeight: 160,
  },
}))

const FooterContent = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: 1440,
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 38,
  paddingLeft: 84,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    justifyContent: 'center',
  },
}))

const PreLogoText = styled('div')(({ theme }) => ({
  alignItems: 'center',
  paddingRight: 12,
  color: '#FFFFFF',
}))

const SocialsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: 10,
  paddingTop: 38,
  paddingRight: 60,
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
}))

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  color: 'transparent',
  width: '40px',
  height: '40px',
  borderRadius: 50,
  '&:hover': {
    '&.facebook': {
      backgroundColor: '#4267B2',
    },
    '&.twitter': {
      backgroundColor: '#1DA1F2',
    },
    '&.linkedin': {
      backgroundColor: '#2867B2',
    },
    '&.youtube': {
      backgroundColor: '#FF0000',
    },
  },
}))

const IconStyle = { margin: 'auto', justifySelf: 'center' }

const Footer: React.FC = () => {
  return (
    <FooterContainer data-testid="footer">
      <FooterContent>
        <LogoContainer>
          <PreLogoText>Powered by </PreLogoText>
          <FooterLogo />
        </LogoContainer>
        <SocialsContainer>
          <StyledLink
            className="facebook"
            href="https://www.facebook.com/Property.Exchange.Australia"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon style={IconStyle} />
          </StyledLink>
          <StyledLink className="twitter" href="https://twitter.com/PEXA_AUSTRALIA" target="_blank" rel="noreferrer">
            <TwitterIcon style={IconStyle} />
          </StyledLink>
          <StyledLink
            className="linkedin"
            href="https://www.linkedin.com/company/pexa/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon style={IconStyle} />
          </StyledLink>
          <StyledLink className="youtube" href="https://www.youtube.com/c/PexaAu" target="_blank" rel="noreferrer">
            <YouTubeIcon style={IconStyle} />
          </StyledLink>
        </SocialsContainer>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer
