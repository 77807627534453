import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import chevron from '../../img/Background-path-predeparture.svg'
import vector from '../../img/List-vector.svg'
import predeparture from '../../img/Predeparture-image-dashboard.svg'
import mobileimage from '../../img/Predeparture-image-mobile.svg'
import ContentContainer from '../Container/ContentContainer'
import ImageBlockContainer from '../Container/ImageBlockContainer'
import SectionContainer from '../Container/SectionContainer'
import TextBlockContainer from '../Container/TextBlockContainer'

const BackgroundChevron = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '8%',
  [theme.breakpoints.down('sm')]: {
    left: '20%',
  },
}))

const Title = styled('div')(({ theme }) => ({
  color: '#FFFFFF',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: 38,
  lineHeight: '41.8px',
  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '36px',
    textAlign: 'center',
  },
}))

const ListContainer = styled('div')(({ theme }) => ({
  color: '#FFFFFF',
  marginTop: 24,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 35,
    marginRight: 35,
  },
}))

const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
}))

const ListVector = styled('img')(({ theme }) => ({
  position: 'absolute',
  paddingTop: 10,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 5,
  },
}))

const ListText = styled('div')(({ theme }) => ({
  marginLeft: 60,
  fontFamily: 'Libre Franklin',
  fontWeight: 300,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '32px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 40,
    fontSize: 16,
    lineHeight: '25.6px',
  },
}))

const PredepartureImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const MobileResponsiveImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const PredepartureLayout: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const contentFlexDirection = isMobile ? 'column-reverse' : 'row'
  const contentMinHeight = isMobile ? 626 : 737
  const imageMarginTop = isMobile ? 32 : 102
  const imageMarginLeft = isMobile ? 0 : 120
  const textMarginTop = isMobile ? 64 : 252
  const textMarginLeft = isMobile ? 0 : 250

  return (
    <SectionContainer backgroundColor="#1E2142">
      <ContentContainer flexDirection={contentFlexDirection} minHeight={contentMinHeight}>
        <ImageBlockContainer marginTop={imageMarginTop} marginLeft={imageMarginLeft}>
          <MobileResponsiveImage src={mobileimage} alt="Pre-departure dashboards" />
          <PredepartureImage style={{ minWidth: 600 }} src={predeparture} alt="Pre-departure dashboards" />
        </ImageBlockContainer>
        <TextBlockContainer marginTop={textMarginTop} marginLeft={textMarginLeft}>
          <Title>Pre-departure</Title>
          <ListContainer>
            <ListItem>
              <ListVector src={vector} alt="list vector" />
              <ListText>View all upcoming PEXA Exchange</ListText>
            </ListItem>
            <ListItem>
              <ListVector style={{ paddingBottom: isMobile ? 26 : 30 }} src={vector} alt="list vector" />
              <ListText>Workspaces where you are in the role of Outgoing Mortgagee</ListText>
            </ListItem>
            <ListItem>
              <ListVector style={{ paddingBottom: 30 }} src={vector} alt="list vector" />
              <ListText>See where your refinancing customers are moving to</ListText>
            </ListItem>
          </ListContainer>
        </TextBlockContainer>
        <BackgroundChevron src={chevron} alt="background chevron" />
      </ContentContainer>
    </SectionContainer>
  )
}

export default PredepartureLayout
