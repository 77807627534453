import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import App from './components/App'
import reportWebVitals from './reportWebVitals'

import './index.css'

ReactGA.initialize('UA-53577508-1', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
})
ReactGA.pageview(window.location.pathname)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
