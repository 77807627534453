const getEnvironment = (): string => {
  const { host } = window.location

  if (host.includes('localhost')) {
    return 'dpr1.'
  }
  const environment = host.match(/dpr[d]/g)
  if (environment) {
    return `${environment}.`
  }
  return ''
}

export default getEnvironment
