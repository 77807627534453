import React from 'react'
import { styled } from '@mui/material/styles'

const StyledTextBlockContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'style',
})(({ style, theme }) => ({
  zIndex: style && style.zIndex,
  marginTop: style && style.marginTop,
  marginLeft: style && style.marginLeft,
  marginRight: style && style.marginRight,
  maxWidth: style && style.maxWidth,
  color: style && style.color,
  textAlign: style && style.textAlign,
}))

interface TextBlockContainerProps {
  marginTop?: number | string
  marginLeft?: number | string
  marginRight?: number | string
  maxWidth?: number | string
  color?: string
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
  zIndex?: number
}

const TextBlockContainer: React.FC<TextBlockContainerProps> = ({
  marginTop = 208,
  marginLeft = 89,
  marginRight = 0,
  maxWidth = 480,
  color = '#FFFFFF',
  textAlign = 'left',
  zIndex = 2,
  children,
}) => {
  return (
    <StyledTextBlockContainer style={{ marginTop, marginLeft, marginRight, maxWidth, color, textAlign, zIndex }}>
      {children}
    </StyledTextBlockContainer>
  )
}

export default TextBlockContainer
