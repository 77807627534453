import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import clock from '../../img/Capabilities-icon-clock.svg'
import download from '../../img/Capabilities-icon-download.svg'
import filter from '../../img/Capabilities-icon-filter.svg'
import ContentContainer from '../Container/ContentContainer'
import SectionContainer from '../Container/SectionContainer'

const Title = styled('div')(({ theme }) => ({
  color: '#FFFFFF',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: 28,
  lineHeight: '39.2px',
  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '25.2px',
    textAlign: 'left',
  },
}))

const IconContainer = styled('div')(({ theme }) => ({
  marginTop: 93,
  display: 'flex',
  color: '#FFFFFF',
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 32,
    marginLeft: 25,
    flexDirection: 'column',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '25.2px',
    textAlign: 'left',
  },
}))

const IconItem = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    marginBottom: 22,
  },
}))

const Icon = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 64,
    height: 64,
  },
}))

const IconText = styled('div')(({ theme }) => ({
  marginTop: 32,
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 4,
    marginLeft: 14,
    marginRight: 40,
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '25.6px',
  },
}))

const CapabilityLayout: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const minHeight = isMobile ? 400 : 420
  const margin = isMobile ? '64px 25px' : '116px auto'

  return (
    <SectionContainer background="linear-gradient(113.02deg, #C313A7 -14.18%, #1E2142 118.18%)">
      <ContentContainer margin={margin} maxWidth={1110} flexDirection="column" minHeight={minHeight}>
        <Title>
          Designed for financial institutions to&nbsp;
          <a style={{ color: '#FFFF', pointerEvents: 'none' }} href="/">
            improve reporting capabilities
          </a>
          &nbsp;and workflow efficacy with near real-time access to key mortgage performance indicators
        </Title>
        <IconContainer>
          <IconItem>
            <Icon src={clock} alt="clock icon" />
            <IconText>Timely identification of upcoming transactions</IconText>
          </IconItem>
          <IconItem>
            <Icon src={filter} alt="filter icon" />
            <IconText>Multiple data filters help you focus on what’s important</IconText>
          </IconItem>
          <IconItem>
            <Icon src={download} alt="download icon" />
            <IconText>Reports can be downloaded in required format</IconText>
          </IconItem>
        </IconContainer>
      </ContentContainer>
    </SectionContainer>
  )
}

export default CapabilityLayout
