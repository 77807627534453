import React from 'react'
import ReactGA from 'react-ga'
import MuiButton from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import chevron from '../../img/Background-light-path.svg'
import insights from '../../img/Insights-graph.svg'
import refinance from '../../img/Refinance-graph.svg'
import research from '../../img/Research-graph.svg'
import ContentContainer from '../Container/ContentContainer'
import SectionContainer from '../Container/SectionContainer'

const BackgroundChevron = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '34%',
  [theme.breakpoints.down('sm')]: {
    left: '20%',
  },
}))

const Title = styled('div')(({ theme }) => ({
  color: '#C313A7',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: 38,
  lineHeight: '36px',
  marginTop: 80,
  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 32,
    lineHeight: '36px',
    textAlign: 'left',
  },
}))

const ItemContainer = styled('div')(({ theme }) => ({
  marginTop: 82,
  marginLeft: 55,
  marginRight: 55,
  display: 'flex',
  color: '#FFFFFF',
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '28px',
  zIndex: 9,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    marginTop: 32,
    marginLeft: 0,
    marginRight: 0,
    flexDirection: 'column',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '25.2px',
  },
}))

const Item = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    marginBottom: 22,
    flexDirection: 'column',
  },
}))

const ItemTitle = styled('div')(({ theme }) => ({
  color: '#1F2142',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: 28,
  lineHeight: '36px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}))

const ItemImage = styled('img')(({ theme }) => ({
  marginTop: 16,
}))

const ItemText = styled('div')(({ theme }) => ({
  color: '#555555',
  fontFamily: 'Libre Franklin',
  fontWeight: 300,
  fontStyle: 'normal',
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'left',
  margin: 'auto',
  maxWidth: 410,
  [theme.breakpoints.down('sm')]: {
    marginTop: 4,
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '25.6px',
  },
}))

const LearnMoreButton = styled(MuiButton)(({ theme }) => ({
  marginTop: '11px',
  width: 99,
  height: 44,
  background: '#C313A7',
  color: '#FFFFFF',
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 12,
  lineHeight: '16px',
  marginLeft: 10,
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    width: 99,
    height: 44,
  },
  '&:hover': {
    background: '#C313A7',
  },
}))

const CapabilityLayout: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const margin = isMobile ? '64px 25px' : '0px auto'
  const fireGAEvent = (eventAction: string, eventLabel: string) => {
    ReactGA.event({
      category: 'Pexa Insights Landing Page',
      action: eventAction,
      label: eventLabel,
    })
  }
  const handleClick = (event: string, label: string, url: string) => {
    fireGAEvent(event, label)
    window.open(url)
  }

  return (
    <SectionContainer background="none">
      <ContentContainer margin={margin} flexDirection="column" minHeight={800}>
        <Title>More from PEXA Insights</Title>
        <ItemContainer>
          <Item>
            <ItemTitle>Refinance Index</ItemTitle>
            <ItemImage src={refinance} alt="Refinance index graph" />
            <ItemText>
              Weekly insights into Ausralian property refinancing.&nbsp;See market performance and ares of growth.
            </ItemText>
            <LearnMoreButton
              onClick={() =>
                handleClick(
                  'Learn More',
                  'Refinance Index',
                  'https://www.pexa.com.au/insights#insights-refinance-index',
                )
              }
            >
              Learn more
            </LearnMoreButton>
          </Item>
          <Item>
            <ItemTitle>Monthly Insights</ItemTitle>
            <ItemImage src={insights} alt="Monthly insights graph" />
            <ItemText>Timely insights into Australian settlement and mortgage trends.</ItemText>
            <LearnMoreButton
              onClick={() =>
                handleClick('Learn More', 'Monthly Insights', 'https://www.pexa.com.au/insights#monthly-insights')
              }
            >
              Learn more
            </LearnMoreButton>
          </Item>
          <Item>
            <ItemTitle>Latest Research</ItemTitle>
            <ItemImage src={research} alt="Latest research graph" />
            <ItemText>
              View all of <b>PEXA Insights</b> research reports and collaborations with industry partners.
            </ItemText>
            <LearnMoreButton
              onClick={() =>
                handleClick(
                  'Learn More',
                  'Latest Research',
                  'https://www.pexa.com.au/insights#insights-latest-reports1',
                )
              }
            >
              Learn more
            </LearnMoreButton>
          </Item>
        </ItemContainer>
        <BackgroundChevron src={chevron} alt="background chevron" />
      </ContentContainer>
    </SectionContainer>
  )
}

export default CapabilityLayout
