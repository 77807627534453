import React from 'react'
import ReactGA from 'react-ga'
import MuiButton from '@mui/material/Button'
import Link from '@mui/material/Link'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import chevron from '../../img/Background-path.svg'
import mobileresponsive from '../../img/Hero-image-mobile-all.svg'
import single from '../../img/Hero-image-single.svg'
import ContentContainer from '../Container/ContentContainer'
import ImageBlockContainer from '../Container/ImageBlockContainer'
import SectionContainer from '../Container/SectionContainer'
import TextBlockContainer from '../Container/TextBlockContainer'
import getEnvironment from '../util'

const Title = styled('div')(({ theme }) => ({
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 38,
  lineHeight: '41.8px',
  [theme.breakpoints.down('sm')]: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 28,
    lineHeight: '30.8px',
  },
}))

const TextContent = styled('div')(({ theme }) => ({
  marginTop: 16,
  minHeight: 160,
  fontWeight: 300,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '32px',
  maxWidth: 362,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 480,
    minHeight: 117,
  },
}))

const LoginButton = styled(MuiButton)(({ theme }) => ({
  marginTop: '11px',
  width: 180,
  height: 60,
  background: '#C313A7',
  color: '#FFFFFF',
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 20,
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    width: 187,
    height: 44,
  },
  '&:hover': {
    background: '#C313A7',
  },
}))

const DemoLink = styled(Link)(({ theme }) => ({
  display: 'block',
  fontFamily: 'Quicksand',
  fontWeight: 500,
  fontStyle: 'normal',
  fontSize: 20,
  lineHeight: '20px',
  color: '#FFFFFF',
  textDecorationColor: '#FFFFFF',
  marginTop: 20,
  textTransform: 'unset',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 36,
  },
  '&:hover': {
    pointer: 'cursor',
  },
}))

const BackgroundChevron = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '40%',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    left: '20%',
  },
}))

const HeroImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const MobileResponsiveImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const MainHeroSection: React.FC = () => {
  const env = getEnvironment()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const contentJustifyContent = isMobile ? 'normal' : 'left'
  const contentFlexDirection = isMobile ? 'column-reverse' : 'row'
  const contentMinHeight = isMobile ? 670 : 800
  const contentMargin = isMobile ? '0' : '0px auto'
  const textMarginTop = isMobile ? 0 : 208
  const textMarginLeft = isMobile ? 22 : 89
  const textMarginRight = isMobile ? 22 : 0
  const textAlign = isMobile ? 'center' : 'left'
  const imageMarginTop = isMobile ? 112 : 160
  const imageMarginLeft = isMobile ? 33 : 0
  const imageMarginRight = isMobile ? 33 : 0
  const fireGAEvent = (eventAction: string, eventLabel: string) => {
    ReactGA.event({
      category: 'PEXA Insights Landing Page',
      action: eventAction,
      label: eventLabel,
    })
  }
  const handleClick = (event: string, label: string, url: string | null) => {
    fireGAEvent(event, label)
    if (url) {
      window.open(url)
    }
  }

  return (
    <SectionContainer backgroundColor="#1E2142" marginTop={80}>
      <ContentContainer
        margin={contentMargin}
        justifyContent={contentJustifyContent}
        flexDirection={contentFlexDirection}
        minHeight={contentMinHeight}
      >
        <TextBlockContainer
          marginTop={textMarginTop}
          marginLeft={textMarginLeft}
          marginRight={textMarginRight}
          textAlign={textAlign}
        >
          <Title>
            <b>MyView</b> is your online reporting tool to monitor mortgage activity
          </Title>
          <TextContent>
            <p>
              Providing timely and powerful insights – at state, region and suburb level – detailing the past and
              upcoming movement of your mortgage customers.
            </p>
          </TextContent>
          <LoginButton
            onClick={() =>
              handleClick(
                'Login',
                `https://myview.${env}pexainsights.com.au`,
                `https://myview.${env}pexainsights.com.au`,
              )
            }
          >
            Login
          </LoginButton>
          <DemoLink
            variant="button"
            href="mailto:insights@pexa.com.au"
            onClick={() => handleClick('Book a Demo', 'mailto:insights@pexa.com.au', null)}
          >
            Book a Demo
          </DemoLink>
        </TextBlockContainer>
        <ImageBlockContainer marginTop={imageMarginTop} marginLeft={imageMarginLeft} marginRight={imageMarginRight}>
          <MobileResponsiveImage src={mobileresponsive} alt="hero image" />
          <HeroImage src={single} alt="hero background" />
        </ImageBlockContainer>
        <BackgroundChevron src={chevron} alt="Background chevron" />
      </ContentContainer>
    </SectionContainer>
  )
}

export default MainHeroSection
