import React from 'react'
import { styled } from '@mui/material/styles'

const StyledContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'style',
})(({ style, theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: style && style.flexDirection,
  margin: style && style.margin,
  justifyContent: style && style.justifyContent,
  maxWidth: style && style.maxWidth,
  minHeight: style && style.minHeight,
}))

interface ContentContainerProps {
  id?: string
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  margin?: number | string
  maxWidth?: number | string
  minHeight?: number
  justifyContent?: string
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  id,
  flexDirection = 'row',
  margin = '0px auto',
  maxWidth = 1440,
  minHeight = 800,
  justifyContent = 'normal',
  children,
}) => {
  return (
    <StyledContentContainer id={id} style={{ flexDirection, margin, maxWidth, minHeight, justifyContent }}>
      {children}
    </StyledContentContainer>
  )
}

export default ContentContainer
