import React from 'react'
import MuiAppBar from '@mui/material/AppBar'
import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import { ReactComponent as PexaLogo } from '../../img/PEXA-Insights-Logo.svg'

const LoginButton = styled(MuiButton)(({ theme }) => ({
  fontFamily: 'Quicksand',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  width: 87,
  height: 44,
  borderRadius: 4,
  color: '#C313A7',
  border: '2px solid #C313A7',
  textTransform: 'none',
  '&:hover': {
    background: '#FFFFFF',
  },
}))

const HeaderContainer = styled(MuiAppBar)(({ theme }) => ({
  display: 'block',
  boxShadow: 'none',
  minHeight: 80,
  background: '#FFFFFF',
  [theme.breakpoints.down('sm')]: {
    minHeight: 64,
  },
}))

const HeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: 1440,
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
}))

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingTop: 20,
  paddingLeft: 28,
  height: 40,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 16,
    paddingLeft: 18,
    width: 191,
    height: 32,
  },
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'none',
  paddingTop: 18,
  paddingRight: 29,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 10,
    paddingRight: 16,
  },
}))

const Header: React.FC = () => {
  const handleClick = (url: string) => {
    window.open(url)
  }

  return (
    <div>
      <HeaderContainer position="fixed" data-testid="header">
        <HeaderContent>
          <LogoContainer>
            <PexaLogo />
          </LogoContainer>
          <ButtonContainer>
            <LoginButton onClick={() => handleClick('https://myview.dpr1.pexainsights.com.au')}>Log in </LoginButton>
          </ButtonContainer>
        </HeaderContent>
      </HeaderContainer>
    </div>
  )
}

export default Header
