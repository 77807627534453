import React from 'react'

import Footer from './Footer'
import Header from './Header'
import CapabilityLayout from './Sections/CapabilityLayout'
import MainHeroSection from './Sections/MainHeroSection'
import PexaInsightsLayout from './Sections/PexaInsightsLayout'
import PipelineLayout from './Sections/PipelineLayout'
import PredepartureLayout from './Sections/PredepartureLayout'
import VolumeValueLayout from './Sections/VolumeValueLayout'

import '../styles/App.css'

const App: React.FC = () => {
  return (
    <div className="App" data-testid="root">
      <Header />
      <MainHeroSection />
      <CapabilityLayout />
      <VolumeValueLayout />
      <PredepartureLayout />
      <PipelineLayout />
      <PexaInsightsLayout />
      <Footer />
    </div>
  )
}

export default App
